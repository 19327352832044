<template>
  <div>
    <div class="imgWrapper" v-show="modalImg" @click="modalImg = undefined">
      <p class="imgWrapperText">loading image</p>
      <b-img class="imgWrapperImage" fluid-grow :src="`./imgs/${modalImg}`" alt="" />
      <div class="imgWrapperIcon">
        <b-icon class="imgWrapperIcon" icon="x" />
      </div>
    </div>
    <div class="hero">
      <p>I did...</p>
      <p>a lot of projects in the past.</p>
      <p>Scroll down to explore a selection of my favourite projects.</p>
      <p>
        <router-link id="back" title="◄ go back" to="/">◄ back</router-link>
      </p>
    </div>
    <div class="project">
      <h1 id="bikeshare">bikeshare<a href="#bikeshare" id="hyperlink">
          <b-icon icon="link45deg" class="icon" /></a></h1>
      <span>general: 2020-now for university w/ Tara Monheim, Linn Klaas</span><br>
      <span>skills: frontend-development, backend-development, prototyping, electronic circuits, 3D-rendering,
        video-production</span><br>
      <span>tools: Vue.js, Node.js, Express, Mongoose, Arduino, Figma, TheThingsNetwork</span><br>
      <span>images: [<a @click="showImg('bikeshare.png')" id="hyperlink">App</a>]</span><br>
      <span>links: [<a href="https://www.youtube.com/watch?v=ObVLkD01f1Y" id="hyperlink" target="_blank">commercial</a>]
        [<a href="https://www.youtube.com/watch?v=PE703QMaDBI" id="hyperlink"
          target="_blank">making-of</a>] [<a href="https://portfolio.hfg-gmuend.de/w-2021/projekte/bike-share" id="hyperlink" target="_blank">documentation</a>]</span><br><br>
      <span>bikeshare is a connected full-stack system allowing students and lecturers at our university to borrow bikes
        and lend out their bikes.</span><br>
    </div>
    <div class="project">
      <h1 id="discord-bot">discord bot<a href="#discord-robot" id="hyperlink">
          <b-icon icon="link45deg" class="icon" /></a></h1>
      <span>general: 2019-now privately</span><br>
      <span>skills: frontend-development, backend-development, databases, discord api, external apis, oauth</span><br>
      <span>tools: dicord.js, Node.js, Express, Vue.js</span><br>
      <span>images: -</span><br>
      <span>links: [<a href="https://discordbot.craftycram.net/" id="hyperlink"
          target="_blank">dashboard</a>]</span><br><br>
      <span>A discord bot I've created over the last few years. It solves and automates most of the repetitive tasks in our everyday life using discord.</span><br>
      <span>It's the third version including many features like a huge soundboard, many features for moderation, as well as some other fun stuff.</span><br>
      <span>I mainly created it for moderation purposes and I will continue adding new features. Take a look at the dashboard where you can manage some of it's features.</span><br>
    </div>
    <div class="project">
      <h1 id="helpdesk">helpdesk bot &amp; dashboard<a href="#helpdesk" id="hyperlink">
          <b-icon icon="link45deg" class="icon" /></a></h1>
      <span>general: 2021-now for university</span><br>
      <span>skills: frontend-development, backend-development, oauth flows</span><br>
      <span>tools: Vue.js, Node.js, Express, Mongoose, SlackAPI, Firestore</span><br>
      <span>images: [<a @click="showImg('helpdesk-landingpage.png')" id="hyperlink">Landing Page</a>] [<a @click="showImg('helpdesk-slack.png')" id="hyperlink">Slack-Bot Message</a>] [<a @click="showImg('helpdesk-case.png')" id="hyperlink">Issue Details</a>]</span><br>
      <span>links: [<a href="https://www.helpdesk.hfgiot.cloud" id="hyperlink"
          target="_blank">dashboard</a>]</span><br><br>
      <span>The helpdesk-bot is a bot on our universities Slack-workspace. It detects commonly known issues with our
        online-schooling-platform as well as other tools and systems used by our university and provies instructions on
        how to fix them.</span><br>
      <span>With the dashboard students can lookup their issues instead of using the Slack bot and the support team can
        add new issues to the database.</span><br>
    </div>
    <div class="project">
      <h1 id="helpdesk">company intranet<a href="#helpdesk" id="hyperlink">
          <b-icon icon="link45deg" class="icon" /></a></h1>
      <span>general: 2020-now for <a href="https://itmr-dev.de" target="_blank" rel="noopener noreferrer">ITMR Developments</a></span><br>
      <span>skills: frontend-development, backend-development, document rendering, email automation, invoice management, customer management</span><br>
      <span>tools: Vue.js, Node.js, Express, Mongoose, google cloudstorage</span><br>
      <span>images: [<a @click="showImg('intranet.png')" id="hyperlink">Intranet</a>]</span><br>
      <span>links: -</span><br><br>
      <span>An interal tool for ITMR Developments to manage customers, track worktime, create invoices and send emails.</span><br>
      <span>Sadly I can't show many screenshots or more information due to customer privacy.</span><br>
    </div>
    <div class="project">
      <h1 id="skills">skills social plattform<a href="#skills" id="hyperlink">
          <b-icon icon="link45deg" class="icon" /></a></h1>
      <span>general: 2021-now for university w/ Lucy Hochholdinger, <a href="https://tobiasmichaely.com/" id="hyperlink"
          target="_blank" rel="noopener noreferrer">Tobias Michealy</a>, <a
          href="https://www.linkedin.com/in/malte-opderbeck-347481211/" id="hyperlink" rel="noopener noreferrer"
          target="_blank">Malte Opderbeck</a></span><br>
      <span>skills: frontend-development, backend-development, oauth flows</span><br>
      <span>tools: Vue.js, Node.js, Express, Directus, Firestore</span><br>
      <span>images: [<a @click="showImg('skills-search.png')" id="hyperlink">Search</a>] [<a @click="showImg('skills-results.png')" id="hyperlink">Search Results</a>] [<a @click="showImg('skills-profile.png')" id="hyperlink">User Profile</a>]</span><br>
      <span>links: [<a href="https://skills.hfgiot.cloud" id="hyperlink" target="_blank">website</a>]</span><br><br>
      <span>As a part of the international seminar week we created this social network where you can look up topics you
        need support with.
        The plattform then shows you users that have skills to help.</span><br>
    </div>
    <div class="project">
      <h1 id="gtav-mods">GTA V mods<a href="#gtav-mods" id="hyperlink">
          <b-icon icon="link45deg" class="icon" /></a></h1>
      <span>general: 2020</span><br>
      <span>skills: gta mod developing, c#, .net-framework</span><br>
      <span>tools: rage api, rage native api, lspdfr api</span><br>
      <span>images: [<a @click="showImg('garagevehiclesave.png')" id="hyperlink">garagevehiclesave</a>]
        [<a @click="showImg('mechanicbackup.png')" id="hyperlink">mechanicbackup</a>]</span><br>
      <span>links: [<a href="https://www.youtube.com/watch?v=Sy346ojPvfI" id="hyperlink" target="_blank">gvs demo</a>]
        [<a href="https://www.youtube.com/watch?v=GZH6WLgXkU8" id="hyperlink" target="_blank">mb
          tutorial</a>]  [<a href="https://github.com/craftycram/GarageVehicleSavePlugin/" id="hyperlink" target="_blank">gvs
          source code</a>]  [<a href="https://github.com/craftycram/MechanicBackupPlugin" id="hyperlink" target="_blank">mb
          source code</a>]</span><br><br>
      <span>I love patrolling the city of Los Santos using lspdfr, but sometimes I just got annoyed with my police cars
        despawning or having to use a mod menu to repair them.
        Therefore I taught myself in mod development to create some mods that solve that issues for me by adding more
        realism to the game.</span><br>
    </div>
    <div class="project">
      <h1 id="discovery-robot">discovery robot<a href="#discovery-robot" id="hyperlink">
          <b-icon icon="link45deg" class="icon" /></a></h1>
      <span>general: 2020 for university</span><br>
      <span>skills: frontend-development, backend-development, prototyping, electronic circuits, 3D-modeling &amp;
        printing</span><br>
      <span>tools: Vue.js, Node.js, Express, socket.io</span><br>
      <span>images: [<a @click="showImg('robot.jpg')" id="hyperlink">robot</a>]</span><br>
      <span>links: [<a
          href="https://ausstellung.hfg-gmuend.de/s-2020/kurse/technische-grundlagen-2-programmiersprachen-2"
          id="hyperlink" target="_blank">livestream (offline)</a>] [<a
          href="https://github.com/craftycram/rc-frontend"
          id="hyperlink" target="_blank">frontend code</a>] [<a
          href="https://github.com/craftycram/rc-backend"
          id="hyperlink" target="_blank">backend code</a>]</span><br><br>
      <span>Usually at the end of each semester there's an exhibition of all the projects at our university. Due to
        covid-19 and lockdown that wasn't possible.</span><br>
      <span>To still allow going through the universities hallways to look at the projects we built robots that visitors
        can control via their browser from home and see a livestream of the robots onboard camera.</span><br>
    </div>
    <div class="project">
      <h1>other projects</h1>
      <p>I also did some more projects that are listed on my company's &amp; and video production label's websites:</p>
      <p><a id="videography" href="https://silkframe-media.de" target="_blank">silframe media</a></p>
      <p><a id="coding" href="https://itmr-dev.de" target="_blank">ITMR Developments</a></p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Projects',
    data() {
      return {
        modalImg: '',
      };
    },
    methods: {
      showImg(img) {
        this.modalImg = img;
        // this.$bvModal.show('imgModal')
      },
    }
  }
</script>

<style scoped>
  p {
    color: #3b3b3b;
    font-size: 300%;
    margin: 0;
  }
  .hero {
    height: 100vh;
  }

  .project {
    margin-bottom: 40rem;
  }

  .imgWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(10, 10, 10, 0.5);
    padding: 5rem;
  }

  .imgWrapperText {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .imgWrapperImage {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 20;
    padding: 15rem;
  }

  .imgWrapperIcon {
    z-index: 100;
    height: 2rem;
    width: 2rem;
    color: black;
    background-color: white;
    border-radius: 0.2rem;
    position: fixed;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  .imgWrapperIcon:hover {
    color: white;
    background-color: black;
  }

  .icon {
    margin-left: 1rem;
    font-size: 16pt;
  }
</style>